import { useEffect, useRef } from 'react'
import { css } from '@emotion/react'
import IconGlobe from 'driverama-core/images/icons/IconGlobe.svg'
import {
  SContainer,
  SCountryControl,
  SFilterInput,
  SFlag,
  SIconChevronDown
} from './SelectCountryPicker.styled'
import { Flag } from 'driverama-core/components/flag/Flag'
import { SetState } from 'driverama-core/utils/types'
import { size } from 'driverama-core/styles/spacing'

interface Props {
  value: string | undefined
  filter: string
  setFilter: SetState<string>
  isOpened: boolean
  label: string
  setFilterFocused: (newState: boolean) => void
  width?: number
  disabled?: boolean
  readOnly?: boolean
}

export function SelectCountryPicker({
  value,
  filter,
  setFilter,
  isOpened,
  label,
  setFilterFocused,
  width,
  disabled,
  readOnly
}: Props) {
  const filterRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const element = filterRef.current

    if (element) {
      element.addEventListener('focus', () => setFilterFocused(true))
      element.addEventListener('blur', () => setFilterFocused(false))
    }

    return () => {
      if (element) {
        element.removeEventListener('focus', () => setFilterFocused(true))
        element.removeEventListener('blur', () => setFilterFocused(false))
      }
    }
  }, [setFilterFocused])

  return (
    <SContainer
      disabled={disabled || readOnly}
      onClick={e => {
        if (disabled || readOnly) {
          e.stopPropagation()
        } else if (filterRef.current) {
          filterRef.current.focus()
          setFilter('')
        }
      }}
    >
      <SCountryControl
        isDisabled={disabled}
        isReadOnly={readOnly}
        css={css`
          --left-padding: ${size(3)};
          width: ${width ? width + 'px' : 'auto'};
        `}
        label={label}
      >
        <SFilterInput
          disabled={disabled}
          readOnly={readOnly}
          name="filter"
          value={filter}
          ref={filterRef}
          onChange={e => setFilter(e.target.value)}
          autoComplete={'off'}
        />
        {!value ? <SFlag as={IconGlobe} /> : <SFlag as={Flag} name={value} />}
        {!readOnly && <SIconChevronDown open={isOpened} />}
      </SCountryControl>
    </SContainer>
  )
}
