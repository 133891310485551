import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { size } from 'driverama-core/styles/spacing'
import { color, weight } from 'driverama-core/styles/variables'

export const SOption = styled.div<{ selected: boolean }>`
  display: grid;
  grid-template-columns: ${size(4)} ${size(13)} auto;
  gap: ${size(1)};
  text-align: left;
  padding: ${size(3)} ${size(3)};
  cursor: pointer;
  width: 100%;

  :hover,
  :focus {
    background-color: ${color('night-l-800')};
  }

  b {
    color: ${color('black')};
  }

  ${({ selected }) =>
    selected &&
    css`
      font-weight: ${weight('bold')};
      color: ${color('black')};
    `}
`
