import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { FormControl } from 'driverama-core/components/formControl/FormControl'
import { size } from 'driverama-core/styles/spacing'
import { color, time } from 'driverama-core/styles/variables'
import IconChevronDown from 'driverama-core/images/icons/IconChevronDown.svg'

export const SContainer = styled.div<{ disabled?: boolean }>`
  position: relative;

  // prevent cursor: pointer on various nested buttons when disabled
  ${({ disabled }) =>
    disabled &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    `}
`

export const SFilterInput = styled.input`
  padding-left: ${size(8)} !important;
  padding-right: ${size(8)} !important;
`

export const SIconChevronDown = styled(IconChevronDown)<{ open: boolean }>`
  position: absolute;
  right: ${size(2)};

  transition: all ${time('control')} ease-in-out;
  color: ${color('night-l-100')};

  ${({ open }) => open && `transform: rotate(180deg);`}
`

export const SCountryControl = styled(FormControl)`
  cursor: pointer;

  input {
    padding-top: calc(${size(2)} + var(--label-height)) !important;

    & ~ label {
      transform: translateY(${size(1)}) scale(calc(12 / 16)) !important;
    }
  }
`

export const SFlag = styled.svg`
  width: ${size(4)};
  height: ${size(4)};

  position: absolute;
  left: var(--left-padding);
  bottom: 10px;
`
