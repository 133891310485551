import { useTranslation } from 'react-i18next'
import { ZodErrorMap } from 'zod'

export function useDefaultZodErrorMap(): ZodErrorMap {
  const { t } = useTranslation(['core'])

  return (error, ctx) => {
    if (error.message) {
      return { message: error.message }
    }

    if (error.code === 'invalid_type') {
      return { message: t('core:validation_required') }
    }

    if (error.code === 'too_big') {
      if (error.type === 'number') {
        return {
          message: error.inclusive
            ? t('core:validation_number_too_big_inclusive', {
                max: error.maximum
              })
            : t('core:validation_number_too_big', { max: error.maximum })
        }
      }

      return {
        message: error.inclusive
          ? t('core:validation_string_too_big_inclusive', {
              max: error.maximum
            })
          : t('core:validation_string_too_big', { max: error.maximum })
      }
    }

    if (error.code === 'too_small') {
      if (error.type === 'number') {
        return {
          message: error.inclusive
            ? t('core:validation_number_too_small_inclusive', {
                min: error.minimum
              })
            : t('core:validation_number_too_small', { min: error.minimum })
        }
      }

      return {
        message: error.inclusive
          ? t('core:validation_string_too_small_inclusive', {
              min: error.minimum
            })
          : t('core:validation_string_too_small', { min: error.minimum })
      }
    }

    if (error.code === 'invalid_enum_value') {
      if (ctx.data === undefined || ctx.data === null) {
        return { message: t('core:validation_required') }
      }
    }

    return { message: ctx.defaultError }
  }
}
