import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import Input, {
  CountrySelectComponentProps,
  parsePhoneNumber
} from 'react-phone-number-input'
import { FormControl } from 'driverama-core/components/formControl/FormControl'
import {
  SelectCountryOption,
  SelectCountryOptionProps
} from 'driverama-core/components/select/selectCountry/SelectCountryOption'
import { SelectCountryPicker } from 'driverama-core/components/select/selectCountry/SelectCountryPicker'
import { Dropdown } from 'driverama-core/components/dropdown/Dropdown'
import {
  COUNTRY_INTL,
  ENABLED_COUNTRIES,
  getPhoneCountryCode
} from './PhoneInput.utils'
import { css, SerializedStyles } from '@emotion/react'
import { color, radius } from 'driverama-core/styles/variables'
import { isString } from 'driverama-core/utils/types'
import { Flex } from 'driverama-core/components/Flex'
import { useIsFocused, useSafariCheck } from 'driverama-core/utils/hooks'
import { isRegion } from 'driverama-core/utils/env'

const PhoneInputCountryComponent = forwardRef<
  HTMLSelectElement,
  CountrySelectComponentProps & {
    isInModal?: boolean
    label?: string
    onChangeCountry?: (value: string | undefined) => void
    onOpen?: () => void
    onClose?: () => void
    isBorderless?: boolean
    readOnly?: boolean
  }
>((props, _) => {
  const [filter, setFilter] = useState(
    props.value ? getPhoneCountryCode(props.value) : ''
  )

  const [filterFocused, setFilterFocused] = useIsFocused()

  const [opened, setOpened] = useState<boolean>(false)

  function onOpen() {
    setOpened(true)
    props.onOpen?.()
  }

  function onClose() {
    setOpened(false)
    props.onClose?.()

    if (props.value) {
      setFilter(getPhoneCountryCode(props.value))
    }
  }

  const onChange = props.onChange
  const onChangeCountry = props.onChangeCountry

  const handleChange = useCallback(
    (value: string | undefined) => {
      onChange?.(value === COUNTRY_INTL ? undefined : value)
      onChangeCountry?.(value === COUNTRY_INTL ? undefined : value)
      setFilter(value ? getPhoneCountryCode(value) : '')
    },
    [onChange, onChangeCountry]
  )

  useEffect(() => {
    if (!filterFocused) {
      setFilter(props.value ? getPhoneCountryCode(props.value) : '')
    }
  }, [filterFocused, props.value])

  return (
    <Dropdown
      trigger={
        <SelectCountryPicker
          disabled={props.disabled}
          readOnly={props.readOnly}
          setFilterFocused={setFilterFocused}
          filter={filter}
          setFilter={setFilter}
          value={props.value}
          isOpened={opened}
          label={props.label || ''}
          width={126}
        />
      }
      padding="none"
      onClose={onClose}
      onOpen={onOpen}
      placement={'bottom-start'}
      yOffset={-3}
      isInModal={props.isInModal}
      css={css`
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: ${radius('input')};
        border-bottom-right-radius: ${radius('input')};

        border: ${props.isBorderless
          ? 'none'
          : `2px solid ${color('night-l-650')}`};
        width: 130px;
      `}
    >
      <SelectCountryPhoneOptions
        filter={filter}
        options={props.options}
        onChange={handleChange}
        selected={props.value}
      />
    </Dropdown>
  )
})

const PhoneInputComponent = forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & {
    error?: ReactNode
    label?: string
    required?: boolean
    isBorderless?: boolean
  }
>(({ label, required, isBorderless, ...props }, ref) => {
  const phoneNumberMetaData = useMemo(
    () => (isString(props.value) ? parsePhoneNumber(props.value) : undefined),
    [props.value]
  )

  const value =
    isString(props.value) && phoneNumberMetaData?.countryCallingCode
      ? props.value
          .replace(`+${phoneNumberMetaData?.countryCallingCode}`, '')
          .trim()
      : props.value

  const isSafari = useSafariCheck()

  return (
    <FormControl
      isDisabled={props.disabled}
      isReadOnly={props.readOnly}
      label={label}
      error={props.error}
      css={{ width: '100%', marginLeft: 8 }}
      isRequired={required}
      isBorderless={isBorderless}
    >
      <input
        {...props}
        value={value}
        data-testid="phone"
        name={props.name ?? 'tel'}
        ref={ref}
        //event in safari omits country prefix when using autocomplete
        autoComplete={isSafari ? 'off' : 'tel'}
      />
    </FormControl>
  )
})

export function PhoneInput(props: {
  value: string | undefined
  onChange: (value: string) => void
  defaultCountry?: string
  error?: ReactNode
  countryLabel: string
  phoneLabel?: ReactNode
  containerComponent?: ReactNode
  onOpen?: () => void
  onClose?: () => void
  disabled?: boolean
  readOnly?: boolean
  inputName?: string
  isInModal?: boolean
  wrapperStyles?: SerializedStyles
  isRequired?: boolean
  isBorderless?: boolean
}) {
  const [defaultCountry, setDefaultCountry] = useState<string>(
    props.defaultCountry ?? isRegion('nl') ? 'NL' : 'DE'
  )

  const onChangeCountry = (value: string | undefined) => {
    if (value) {
      setDefaultCountry(value)
    }
  }

  useEffect(() => {
    if (props.defaultCountry) {
      onChangeCountry(props.defaultCountry)
    }
  }, [props.defaultCountry])

  return (
    <div css={{ position: 'relative' }}>
      <Input
        name={props.inputName}
        disabled={props.disabled}
        readOnly={props.readOnly}
        value={props.value || ''}
        defaultCountry={defaultCountry}
        countries={ENABLED_COUNTRIES}
        onChange={props.onChange}
        // @ts-expect-error Cannot force TS to allow this, as @types has incorrect types
        inputComponent={PhoneInputComponent}
        numberInputProps={{
          error: props.error,
          label: props.phoneLabel,
          disabled: props.disabled,
          readOnly: props.readOnly,
          required: props.isRequired,
          isBorderless: props.isBorderless
        }}
        countrySelectComponent={PhoneInputCountryComponent}
        countrySelectProps={{
          label: props.countryLabel,
          onChangeCountry,
          onOpen: props.onOpen,
          onClose: props.onClose,
          disabled: props.disabled,
          readOnly: props.readOnly,
          isInModal: props.isInModal
          // isBorderless: props.isBorderless
        }}
        css={[{ display: 'flex' }, props.wrapperStyles]}
      />
    </div>
  )
}

interface SelectCountryPhoneOptionsProps {
  onChange: (newValue: string | undefined) => void
  onClick?: () => void
  options?: SelectCountryOptionProps[]
  selected: string | undefined
  filter: string
}

export function SelectCountryPhoneOptions({
  onChange,
  options,
  filter,
  onClick,
  selected
}: SelectCountryPhoneOptionsProps) {
  if (!options) {
    return null
  }

  const filterQuery = filter.toLowerCase()

  const optionsWithNumberPrefix = useMemo(
    () =>
      options.map(option => ({
        ...option,
        code: option.value
          ? getPhoneCountryCode(option.value).toLowerCase()
          : ''
      })),
    [options]
  )

  const filteredOptions = optionsWithNumberPrefix.filter(o =>
    filter
      ? o.label.toLowerCase().includes(filterQuery) ||
        o.value?.toLowerCase().includes(filterQuery) ||
        o.code.includes(filterQuery)
      : true
  )

  return (
    <Flex variant="column" css={{ height: '100%' }}>
      {filteredOptions.map((option, i) => (
        <SelectCountryOption
          onClick={onClick}
          selected={option.value === selected}
          key={i}
          {...option}
          onChange={onChange}
          filterQuery={filterQuery}
          label={option.value || ''}
        />
      ))}
    </Flex>
  )
}
