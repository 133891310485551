import { color } from 'driverama-core/styles/variables'
import { SOption } from './SelectCountryOption.styled'
import { Flag } from 'driverama-core/components/flag/Flag'
import Highlighter from 'react-highlight-words'
import { size } from 'driverama-core/styles/spacing'

export interface SelectCountryOptionProps {
  value?: string | undefined
  label: string
}

export function SelectCountryOption({
  value,
  label,
  onChange,
  onClick,
  code,
  filterQuery,
  selected
}: SelectCountryOptionProps & {
  onChange: (newValue: string | undefined) => void
  onClick?: () => void
  code?: string
  filterQuery: string
  selected: boolean
}) {
  if (!value) {
    return null
  }

  return (
    <SOption
      onClick={() => {
        onClick?.()
        onChange(value)
      }}
      selected={selected}
    >
      <Flag name={value} />

      {code && (
        <Highlighter
          searchWords={[filterQuery]}
          autoEscape={true}
          textToHighlight={code}
          css={{ color: color('night-d-200'), width: size(13) }}
          highlightTag={'b'}
        />
      )}

      <Highlighter
        searchWords={[filterQuery]}
        autoEscape={true}
        textToHighlight={label}
        css={{ width: size(30) }}
        highlightTag={'b'}
      />
    </SOption>
  )
}
